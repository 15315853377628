// Welcome to Code in Framer
// Get Started: https://www.framer.com/developers/
import type { Event } from "./Events.tsx"

import {
    SunIcon,
    MoonIcon,
    FireIcon,
    QuestionMarkCircleIcon,
    LightBulbIcon,
    MagnifyingGlassIcon,
    BuildingLibraryIcon,
    InformationCircleIcon,
    AcademicCapIcon,
    GlobeEuropeAfricaIcon,
    ChatBubbleOvalLeftEllipsisIcon,
    RocketLaunchIcon,
    CalendarIcon,
    MapPinIcon,
    SparklesIcon,
} from "https://cdn.jsdelivr.net/npm/@heroicons/react@2.1.5/24/outline/esm/index.js"

export default function _bondingEvent({ event }: { event: Event }) {
    return (
        <div
            style={{
                border: "1px solid rgb(216 216 216)",
                display: "flex",
                height: "100%",
                fontFamily: "Poppins",
                flexDirection: "column",
                paddingInline: "1.5em",
                paddingBlockStart: "1.5em",
                paddingBlockEnd: "1.25em",
                gap: "1em",
                borderRadius: "1.75rem",
                boxShadow:
                    "0 0 #0000,0 0 #0000,0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)",
            }}
        >
            <h3
                style={{
                    margin: 0,
                    fontSize: "1.25em",
                    fontWeight: 500,
                    lineHeight: "1.5em",
                }}
            >
                {event.title}
            </h3>
            <div
                style={{
                    display: "flex",
                    rowGap: "1em",
                    columnGap: "1em",
                    alignItems: "center",
                    flexWrap: "wrap",
                }}
            >
                <span
                    style={{
                        fontWeight: "bold",
                        letterSpacing: "0.5",
                        fontSize: "0.75em",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        justifyItems: "center",
                        gap: "0.25em",
                    }}
                >
                    <CalendarIcon
                        style={{
                            width: "1em",
                            height: "1em",
                            marginBlockEnd: "0.25em",
                        }}
                    />
                    {event.startDate.day} {event.startDate.month}{" "}
                    {event.startDate.year}
                </span>
                {event.localGroup && (
                    <div
                        style={{
                            display: "flex",
                            gap: "0.25em",
                            justifyItems: "center",
                            fontWeight: "bold",
                            letterSpacing: "0.5",
                            fontSize: "0.75em",
                            color: "#007ec6",
                        }}
                    >
                        <MapPinIcon
                            style={{
                                width: "1em",
                                height: "1em",
                                marginBlockEnd: "0.25em",
                            }}
                        />
                        <span>{event.localGroup}</span>
                    </div>
                )}
                <EventTypeBadge type={getTypeByCategory(event.type)} />
            </div>
            <p
                style={{
                    flexGrow: 0,
                    lineHeight: "1.25em",
                    margin: 0,
                    color: "#3c3c3c",
                    overflow: "hidden",
                    WebkitLineClamp: 3,
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitTextSizeAdjust: "100%",
                    fontWeight: 500,
                    fontFamily: "Poppins",
                    fontSize: "0.875em",
                }}
            >
                {event.description}
            </p>
            <div style={{ flexGrow: 1 }} />
            <a
                href={`https://www1.bonding.de/veranstaltungen.nsf/anmeldungstud?OpenForm&id=${event.id}`}
                style={{
                    color: "white",
                    background: "#007ec6",
                    textAlign: "center",
                    borderRadius: "100dvh",
                    paddingInline: "1em",
                    paddingBlock: "0.5em",
                    textDecoration: "none",
                }}
                target="_blank"
            >
                Mehr Informationen & Anmeldung
            </a>
        </div>
    )
}

type EventTypeProps = {
    type: EventType
}
function EventTypeBadge({ type }: EventTypeProps) {
    const Icon = type.icon
    const color = (eventSize?: EventSize) => {
        switch (eventSize) {
            case "small":
                return "#285a8c"
            case "medium":
                return "#eb506e"
            case "large":
                return "#0aaa96"
            default:
                return "#3c3c3c"
        }
    }
    return (
        <div
            style={{
                color: color(type.size),
                fontWeight: "bold",
                letterSpacing: "0.5",
                fontSize: "0.75em",
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                justifyItems: "center",
                gap: "0.25em",
            }}
        >
            <Icon
                style={{
                    width: "1em",
                    height: "1em",
                    marginBlockEnd: "0.25em",
                }}
            />
            <span>{type.category}</span>
        </div>
    )
}

export type EventSize = "small" | "medium" | "large"

type EventCategory =
    | "Engineering Competition"
    | "European Workshop"
    | "Exkursion"
    | "Fallstudie"
    | "Industry Night"
    | "Infoabend"
    | "Infostand"
    | "Kamin-Abend"
    | "Kompass"
    | "Messe"
    | "nicht bonding"
    | "Runder Tisch"
    | "Semesterplaner"
    | "Sonstige Events"
    | "Thementag"
    | "Training"
    | "Vortrag"
    | "Workshop"
    | "Unbekannt"

type Heroicon = React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
        title?: string | undefined
        titleId?: string | undefined
    } & React.RefAttributes<SVGSVGElement>
>

export type EventType = {
    size?: EventSize
    category: EventCategory
    icon: Heroicon
}

const eventTypes: EventType[] = [
    {
        category: "Engineering Competition",
        size: "medium",
        icon: LightBulbIcon,
    },
    { category: "European Workshop", size: "medium", icon: LightBulbIcon },
    { category: "Exkursion", size: "medium", icon: GlobeEuropeAfricaIcon },
    { category: "Fallstudie", size: "small", icon: MagnifyingGlassIcon },
    { category: "Industry Night", size: "large", icon: MoonIcon },
    { category: "Infoabend", icon: InformationCircleIcon },
    { category: "Infostand", icon: InformationCircleIcon },
    { category: "Kamin-Abend", size: "small", icon: FireIcon },
    { category: "Kompass", size: "small", icon: SparklesIcon },
    { category: "Messe", size: "large", icon: BuildingLibraryIcon },
    { category: "nicht bonding", icon: QuestionMarkCircleIcon },
    { category: "Runder Tisch", size: "small", icon: RocketLaunchIcon },
    { category: "Semesterplaner", icon: CalendarIcon },
    { category: "Sonstige Events", icon: QuestionMarkCircleIcon },
    { category: "Thementag", size: "large", icon: SunIcon },
    { category: "Training", size: "small", icon: AcademicCapIcon },
    {
        category: "Vortrag",
        size: "small",
        icon: ChatBubbleOvalLeftEllipsisIcon,
    },
    { category: "Workshop", size: "small", icon: LightBulbIcon },
]

function getTypeByCategory(category: EventCategory): EventType {
    return (
        eventTypes.find((eventType) => eventType.category === category) || {
            category: "Unbekannt",
            icon: QuestionMarkCircleIcon,
        }
    )
}
